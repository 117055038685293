import axios from "axios";
import LFEStore from "./SessionProvider"
import { Logout } from "./Reducers/SessionSlice";
axios.defaults.withCredentials = true

var BEARER_TOKEN = null

function getToken() {
  return BEARER_TOKEN
}

function setAPIToken(token) {
  console.log("Bearer token set",token)
  BEARER_TOKEN = token
}

let baseURL = 'http://localhost:35151/';
if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {
  baseURL = 'https://api.lysep.com/'
} 

const LysepAPI = axios.create({
    baseURL: baseURL,
    timeout: 60000,
    withCredentials:true
  });
LysepAPI.interceptors.request.use(
    config => {
      config.headers["Authorization"] = "Bearer " + getToken();
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );
LysepAPI.interceptors.response.use(resp => resp, async (err) => {
  if (err?.response?.status === 401 ||err?.response?.status === 403) {
    LFEStore.dispatch(Logout());
  }
  return Promise.reject(err);
});
export {
  LysepAPI,
  setAPIToken
};