import React, { Component, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Login';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Index } from './Index';
import { useCookies } from 'react-cookie';
import {LysepAPI, setAPIToken} from '../Contexts/ApiContext';
import { InitializeSession } from '../Contexts/Reducers/SessionSlice';
import PrivateRoute from './PrivateRoute';
import Dashboard from './PanelRoutes/Dashboard';
import LysepThemeProvider from '../Contexts/ThemeProvider';
import { Backdrop, CircularProgress } from '@mui/material';


function MainLoading() {
    return (<Backdrop
        sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
      >
        <CircularProgress color="inherit" />
      </Backdrop>)
}

export default function Router() {
    
    const session = useSelector(state=>state.session)
    const [cookies,setCookie,removeCookie] = useCookies(["__lfe_session"]);
    const dispatch = useDispatch();
    if(session.IsInitialized && !session.Authenticated && cookies.__lfe_state != null){
        removeCookie("__lfe_state")
        removeCookie("__lfe_token")
    }
    useEffect(()=>{
        if(!session.IsInitialized){
            if(cookies["__lfe_state"]){
                let token = cookies["__lfe_token"];
                setAPIToken(token)
                LysepAPI.post("/api/auth/validate-token").then(resp=>{
                    console.log("resp is",resp)
                    if(resp.data.state){
                        dispatch(InitializeSession({
                            Authenticated:true,
                            User:resp.data.user
                        }))
                    } else{
                        dispatch(InitializeSession({
                            Authenticated:false,
                            User:null
                        }))
                        removeCookie("__lfe_state")
                        removeCookie("__lfe_token")
                    } 
                }).catch(error =>  {
                    dispatch(InitializeSession({
                        Authenticated:false,
                        User:null
                    }))
                    removeCookie("__lfe_state")
                    removeCookie("__lfe_token")
                })
                
            }else{
                removeCookie("__lfe_state")
                removeCookie("__lfe_token")
                dispatch(InitializeSession({
                    Authenticated:false,
                    User:null
                }))
            }
        }
    },[])

    return (
        <LysepThemeProvider>
            {
             session.IsInitialized?( <BrowserRouter>
                <Routes>
                    <Route path="/" Component={Index}>
                    </Route>
                    <Route path='/login' Component={Login}></Route>
                    <Route path='/panel/' Component={PrivateRoute}>
                        <Route path='/panel/dashboard/' Component={Dashboard}/>
                        <Route path='/panel/dashboard/:page' Component={Dashboard}/>
                    
                    </Route>
                </Routes>
            </BrowserRouter>) : MainLoading()
            }
           
        </LysepThemeProvider>
    )
}
