import React, { Component, useEffect, useState } from 'react'
import './Dashboard.scss';
import Sidebar from './../PanelComponents/Sidebar';
import WrapperPanel from './../PanelComponents/WrapperPanel';
import { createContext } from 'react';
import UsersModule from './Routes/UsersModule';
import DashboardModule from './Routes/DashboardModule';
import { Link, Outlet, Route, Routes, useParams } from 'react-router-dom';
import { DashboardCustomizeSharp, DashboardSharp, LogoutSharp, People, PeopleAltSharp, RemoveRedEyeSharp } from '@mui/icons-material';
import { LSRoutes } from '../../LYSEPHelper';
import { useTheme } from '@emotion/react';
import LysepThemeProvider from '../../Contexts/ThemeProvider';
import {LysepAPI} from '../../Contexts/ApiContext';
import { useDispatch } from 'react-redux';
import { Logout as LogoutD } from '../../Contexts/Reducers/SessionSlice';
import PirateCallModule from './Routes/PirateCallModule';
import { useCookies } from 'react-cookie';
import Navbar from '../PanelComponents/Navbar';
import { ToastContainer } from 'react-toastify';
const DashboardContext = createContext()
const DashboardProvider = DashboardContext.Provider;
const DashboardConsumer = DashboardContext.Consumer;
export {DashboardConsumer,DashboardProvider,DashboardContext};
const SIDEBAR_STATE ={
    COLLAPSED:0,
    VISIBLE:1
}
const Modules =[
    {
        Title:"Dashboard",
        Path:"dashboard",
        Icon:<DashboardSharp color='primary'/>,
        Component:<DashboardModule  color="primary"/>
    },
    {
        Title:"Users",
        Path:"users",
        Icon:<PeopleAltSharp color='primary'/>,
        Component:<UsersModule/>
    },
    {
        Title:"Piratecall",
        Path:"piratecall",
        Icon:<RemoveRedEyeSharp color='primary'/>,
        Component:<PirateCallModule/>
    }
]
const Dashboard = (props)=> {
    const theme = useTheme();
    const dispatch = useDispatch()
    let [cookies,setCookie,removeCookie] = useCookies()
    let [state,setState] = useState({
        sidebarState:SIDEBAR_STATE.COLLAPSED,
        dispatch:(state,action)=>{
            switch (action.name) {
                case "SET_SIDEBAR":
                    setState({
                        ...state,
                        sidebarState:action.data
                    })
                    break;
                default:
                    break;
            }
        }
    })
    console.log("theme is",theme.palette.primary.main)
    let {page} = useParams()
    let CModule = Modules.find(t=>t.Path == page)
    if( CModule === undefined||CModule === null) CModule = Modules[0]
    console.log("Rendering",CModule?.Title)
    if(page==="logout"){
        // LysepAPI.post("/api/auth/logout")
        dispatch(LogoutD())
        
    }
    return (
        <div className="PanelRoot" active={state.sidebarState}>
        <LysepThemeProvider>
            <DashboardProvider value={state}>
                <ToastContainer  theme='dark'/>
                <div class={`backdrop ${state.sidebarState === 1? "active" : ""}`} onClick={()=>{
                    state.dispatch(state,{
                        name:"SET_SIDEBAR",
                        data:0
                    })
                }}>


                </div>
                <Navbar></Navbar>
                <Sidebar>
                    {Modules.map((module,i)=>{
                        return (
                        <Link key={i} to={LSRoutes.Dashboard(module.Path)}>
                            <li active={`${page === module.Path?1:0}`}>
                            {module.Icon}
                            <div className="brand">
                                <a>{module.Title}</a>
                            </div>
                        </li>
                    </Link>)
                    })}
                    <Link to={LSRoutes.Dashboard("logout")}>
                        <li active={`${page === "logout"?1:0}`}>
                            <LogoutSharp color='primary'/>
                            <div className="brand">
                                <a>Logout</a>
                            </div>
                        </li>
                    </Link>
                </Sidebar>
                <WrapperPanel>
                    <div className="inner-wrapper">
                        {CModule === undefined ? <></> : CModule.Component}
                    </div>
                </WrapperPanel>
            </DashboardProvider>
        </LysepThemeProvider>
        </div>)
}


export default Dashboard;