import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { DashboardConsumer } from '../PanelRoutes/Dashboard';
import './Navbar.scss';
import { AppBar, Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import { Menu, MenuBookOutlined } from '@mui/icons-material';
import Logo from './LogoComponent';

// import './../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
export default class Navbar extends Component {
    constructor(props){
        super(props)
        this.Logout = this.Logout.bind(this);
    }
    componentDidMount(){
        this.dropdownHandle()
    }
    dropdownHandle(){
        var ddowns =  document.querySelectorAll(".dropdown-item");
        ddowns.forEach(element => {
            var trigger = element
            var triggerpe = trigger.getAttribute("onprocessend")
            
            trigger.onclick=()=>{
                var ddown = element.querySelector(".dropdown-menu")
                var classes = ddown.className.split(" ")

                if(classes.includes("active")){
                    classes = classes.filter(x => x!="active")
                    /*Closing*/
                    setTimeout(() => {
                        // ddown.style.display = "none"
                    }, 300);
                }else{
                    // ddown.style.display = "block"
                    /*Opening*/
                    ddowns.forEach((element)=>{
                        var target = element.querySelector(".dropdown-menu");
                        var target_classes = target.className.split(" ")
                        if(target_classes.includes("active")){
                            target_classes = target_classes.filter(x => x!="active")
                        }
                        target.className = target_classes.join(" ")
                    })
                    classes.push("active")

                }
                ddown.className=classes.join(" ");
            }
        });   
    }
    Logout(){
        
    }
    render() {
        return (
            <Box sx={{ flexGrow: 1 }} className={"navbar"}>
                <AppBar  position="sticky">
                <Toolbar>
                    <DashboardConsumer>
                        {dbContext=>(
                            <>
                            {console.log("SDB",dbContext)}
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={(event)=>{
                                    dbContext.dispatch(dbContext,{name:"SET_SIDEBAR",data:dbContext.sidebarState === 1 ? 0 : 1})}
                                    }>
                                <Menu />
                            </IconButton>
                        </>
                        )}
                    </DashboardConsumer>
                    
                    <Box marginLeft={0} marginRight={1}>
                        <Logo color="#fd7000" className="lysep-logo" small={1} loading={1}/>
                    </Box>
                    <Typography variant="h6" className='logo-typo' component="div" sx={{ flexGrow: 1 }}>
                    LYSEP
                    </Typography>
                    {/* <Button color="inherit">Login</Button> */}
                </Toolbar>
                </AppBar>
          </Box>
                    // <nav>
                    //     <div className="navbar-container">
                    //         <div className="start">
                                
                                
                    //             <DashboardConsumer>
                    //             {Dashboard=>(
                    //                 <>
                    //                 <a className="burgermenu" onClick={(event)=>{
                    //                 Dashboard.dispatch(Dashboard,{
                    //                     name:"SET_SIDEBAR",
                    //                     data:!Dashboard.sidebarState
                    //                 })
                    //                 }}><Menu></Menu></a>
                    //                 <a className="brand">{Dashboard.Title}</a>
                    //                 </>
                    //             )}
                    //             </DashboardConsumer>
                    //         </div>
                            
                    //             <div className="end">
                    //                 <ul className="controls">
                    //                     <li className="dropdown-item nav-item" >
                    //                         <i className="fal fa-user"></i>
                    //                         <div className="dropdown-menu">
                    //                             <ul className="dropdown-list">
                    //                                 <li>Profile</li>
                    //                                 <li className="hr">
                    //                                 </li>
                    //                                 <li onClick={this.Logout}>Exit</li>
                    //                             </ul>
                    //                         </div>
                    //                     </li>

                    //                     <li className="dropdown-item nav-item">
                    //                         <i className="fal fa-globe"></i>
                    //                         <div className="dropdown-menu">
                    //                             <ul className="dropdown-list">
                    //                                 <li>Website</li>
                    //                                 <li>Discord</li>
                                                    
                    //                                 {/* {session.Information.permission&(session.Information.permission.ROOT_PERMISSION || session.Information.permission.MODERATOR || session.Information.permission.ADMINISTRATOR)
                    //                                 ? (<div><li className="hr"/><li>Services</li></div>) : ""
                    //                                 } */}
                                                    
                    //                             </ul>
                    //                         </div>
                    //                     </li>
                    //                 </ul>
                    //             </div>
                           
                    //     </div>
                    // </nav>
                
        )
    }
}
