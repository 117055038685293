import './PirateCallModule.scss'
import LSModule from "../../PanelComponents/LSModule";
import {AppBar, Box, Button, Checkbox, CircularProgress, Container, Dialog, Divider, FormControlLabel, Grid, IconButton, InputBase, List, ListItem, ListItemButton, ListItemText, Modal, Paper, Slide, Snackbar, Toolbar, Typography} from "@mui/material";
import ExclusiveDatagrid from '../../PanelComponents/ExclusiveDatagrid';
import { GridActionsCellItem, GridMenuIcon, GridSearchIcon } from '@mui/x-data-grid';
import { CloseFullscreen, Directions, RemoveRedEye, Search } from '@mui/icons-material';
import {LysepAPI} from '../../../Contexts/ApiContext';
import React from 'react';
import { Backdrop } from '@mui/material';
import { toast } from 'react-toastify';
function clip(value) {
    navigator.clipboard.writeText(value);
  }
function googleMaps(location){
    let url = `https://www.google.com/maps/search/${encodeURI(location)}`;
    window.open(url)
    return url;
}  
function CheckedSearch(props){
    return (
        <Paper
          component="form"
          sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%'}}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={props.title}
            inputProps={{ 'aria-label': props.title }}
            disabled={props.isDisabled}
            value={props.textValue}
            onChange={props.textOnChange}
          />
          
          <Divider sx={{ height: 28, m: 0.5,mr:2 }} orientation="vertical" />
          <FormControlLabel color="success" labelPlacement='end' style={{marginTop:"0.1rem"}} control={<Checkbox onChange={props.checkboxOnChange} checked={props.checkboxValue} />} label="Strict" />
        </Paper>
      );
}
/**
 * @namespace SearchModeBase
 */
const SearchModeBase = {
    INFO:0,
    TEL:1
}
export default class PirateCallModule extends LSModule {
    state = {
        isAddUserOpened: false,
        result: [],
        queryPool:{
        },
        loading:false,
        searchMode:SearchModeBase.INFO,
        showInfoModal:false,
        
        target: {

        }
    }
    constructor(props){
        super(props)
        this.fetch = this.fetch.bind(this)
    }
    queryPoolTextValue(variable){
        return this.state[variable]
    }
    queryPoolCheckValue(variable){
        return  this.state.queryPool[variable+"_strict"] === undefined ? true : this.state.queryPool[variable+"_strict"]
    }
    queryPoolTextState(variable,value){
        let injection = {}
        injection[variable] = value === "" ? undefined : value
        this.setState(state=>{
            return {
                ...state,
                searchMode:variable === "GSM" && value !== "" && value !== undefined ? SearchModeBase.TEL : SearchModeBase.INFO,
                queryPool:{
                    ...state.queryPool,
                    ...injection
                }
            };
        })
    }
    queryPoolCheckboxState(variable,value){
        let injection = {}
        injection[variable+"_strict"] = value ? undefined : value
        this.setState(state=>{
            return {
                ...state,
                queryPool:{
                    ...state.queryPool,
                    ...injection
                }
            };
        })
    }
    copyValue(value,title=null){
        clip(value)
        toast.success(`${title+" " ?? ""}panoya kopyalandı.`);
    }
    fetch(){
        if(Object.keys(this.state.queryPool).length === 0){
            console.log("wtf r duin")
            return;
        }
        let processedQueryPool = {};
        switch (this.state.searchMode) {
            case SearchModeBase.INFO:
                processedQueryPool = {
                    ...this.state.queryPool,
                    GSM:undefined,
                    GSM_strict:undefined
                }
                break;
            case SearchModeBase.TEL:
                processedQueryPool = {
                    GSM:this.state.queryPool.GSM,
                    GSM_strict:this.state.queryPool.GSM_strict
                }
                break;
            default:
                break;
        }
        console.log("Fetching with this query pool",processedQueryPool)
        switch (this.state.searchMode) {
            case SearchModeBase.INFO:
                let promise = LysepAPI.post("/api/piratecall/query",processedQueryPool)
                toast.promise(
                    promise,
                    {
                      pending: 'Searching on LYSEP Database...',
                      success: {
                        render:(res)=>{
                            this.setState(state=>{
                                        return {
                                            ...state,
                                            result:res.data?.data?.results
                                        }
                                    })
                            return `Found ${res.data?.data?.results.length ?? 0} results`;
                        },
                        icon: "🟢",
                      },
                      error: {
                        render:(err)=>{
                          return `Error: ${err.data}`;
                        }
                      }
                    },
                    {
                        autoClose:1000
                    }
                )
                
                break;
            case SearchModeBase.TEL:

                let promise_tel = LysepAPI.post("/api/piratecall/query/gsm",processedQueryPool)
                    toast.promise(
                        promise_tel,
                        {
                        pending: 'Searching on LYSEP Database...',
                        success: {
                            render:(res)=>{
                                this.setState(state=>{
                                            return {
                                                ...state,
                                                result:res.data?.data?.results
                                            }
                                        })
                                return `Found ${res.data?.data?.results.length ?? 0} results`;
                            },
                            icon: "🟢",
                        },
                        error: {
                            render:(err)=>{
                            return `Error: ${err.data}`;
                            }
                        }
                        },
                        {
                            autoClose:1000
                        }
                    )
                
                break;
            default:
                break;
        }
        
        
        
    }
    dataModal(){
        let handleClose = ()=>{
            this.setState(state=>{
                return {
                    ...state,
                    showInfoModal:false
                }
            })
        }
        let jobsColumnsA = [
            {
                field: 'sgkSicilNo',
                headerName: 'Sicil NO',
                width: 280,
            },
            {
                field: 'isyeriUnvani',
                headerName: 'Şirket ÜNVAN',
                width: 280
            },  
            {
                field: 'yetkiTuru',
                headerName: 'Yetki Türü',
                width: 200
            },    
            {
                field: 'yetkililikDurumu',
                headerName: 'Durum',
                width: 140
            },
            
            
        ]
        let jobsColumns = [
            {
                field: 'isyeriSgkSicilNo',
                headerName: 'Sicil NO',
                width: 280,
                primary:true
            },
            {
                field: 'isyeriUnvani',
                headerName: 'Şirket ÜNVAN',
                width: 280
            },            
            {
                field: 'isyeriNaceKodu',
                headerName: 'Nace Kodu',
                width: 130
            },
            {
                field: 'isyeriTehlikeSinifi',
                headerName: 'Tehlike Sınıfı',
                width: 130
            },
            {
                field: 'iseGirisTarihi',
                headerName: 'Giriş Tarihi',
                width: 260
            },
            {
                field: 'calismaDurumu',
                headerName: 'Durum',
                width: 140
            },
            
            
        ]
        return (
            <Dialog
                fullScreen
                open={this.state.showInfoModal}
                onClose={handleClose}
                // TransitionComponent={React.forwardRef(function Transition(props, ref) {
                //     return <Slide direction="up" ref={ref} {...props} />;
                //   })}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {this.state.target?.TC} - {this.state.target?.ADI} {this.state.target?.SOYADI} Detayları
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                    Kapat
                    </Button>
                </Toolbar>
                </AppBar>
                <Grid container spacing={2} >
                    <Grid item  md={6} xs={12}>
                        <List>
                            <ListItemButton>
                                <ListItemText primary="TC" onClick={()=>{this.copyValue(this.state.target?.TC,"TC")}} secondary={this.state.target?.TC} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="ADI" onClick={()=>{this.copyValue(this.state.target?.ADI,"ADI")}} secondary={this.state.target?.ADI} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="SOYADI" onClick={()=>{this.copyValue(this.state.target?.SOYADI,"SOYADI")}} secondary={this.state.target?.SOYADI} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="DOGUMTARIHI" onClick={()=>{this.copyValue(this.state.target?.DOGUMTARIHI,"DOGUMTARIHI")}} secondary={this.state.target?.DOGUMTARIHI} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="ANNEADI" onClick={()=>{this.copyValue(this.state.target?.ANNEADI,"ANNEADI")}} secondary={this.state.target?.ANNEADI} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="ANNETC" onClick={()=>{this.copyValue(this.state.target?.ANNETC,"ANNETC")}} secondary={this.state.target?.ANNETC} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="BABAADI" onClick={()=>{this.copyValue(this.state.target?.BABAADI,"BABAADI")}} secondary={this.state.target?.BABAADI} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="BABATC" onClick={()=>{this.copyValue(this.state.target?.BABATC,"BABATC")}} secondary={this.state.target?.BABATC} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="NUFUSIL" onClick={()=>{this.copyValue(this.state.target?.NUFUSIL,"NUFUSIL")}} secondary={this.state.target?.NUFUSIL} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="NUFUSILCE" onClick={()=>{this.copyValue(this.state.target?.NUFUSILCE,"NUFUSILCE")}} secondary={this.state.target?.NUFUSILCE} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="UYRUK" onClick={()=>{this.copyValue(this.state.target?.UYRUK,"UYRUK")}} secondary={this.state.target?.UYRUK} />
                            </ListItemButton>
                        </List>
                    </Grid>
                    <Grid item md={6} xs={12} my={2}>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Araçlar
                        </Typography>
                        <Divider></Divider>
                        <List>
                            <ListItemButton>
                                <ListItemText primary="Herhangi bir araç bulunamadı" />
                            </ListItemButton>
                        </List>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Ebeveynler
                        </Typography>
                        <Divider></Divider>
                        <List>
                            {
                                this.state.parents?.length>0 ? (
                                    <>
                                        {this.state.parents.map((parent,i)=>(
                                            <ListItemButton onClick={()=>{this.TargetAim(parent)}} key={i}>
                                                <ListItemText primary={`TC:${parent?.TC}`} secondary={`${parent?.ADI} ${parent?.SOYADI}`}/>
                                            </ListItemButton>
                                        ))}
                                    </>
                                ) : (<ListItemButton>
                                    <ListItemText primary="Herhangi bir ebeveyn bulunamadı" />
                                </ListItemButton>)
                            }
                        </List>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Çocuklar
                        </Typography>
                        <Divider></Divider>
                        <List>
                            {
                                this.state.childs?.length>0 ? (
                                    <>
                                        {this.state.childs.map((child,i)=>(
                                            <ListItemButton onClick={()=>{this.TargetAim(child)}} key={i}>
                                                <ListItemText primary={`TC:${child?.TC}`} secondary={`${child?.ADI} ${child?.SOYADI}`}/>
                                            </ListItemButton>
                                        ))}
                                    </>
                                ) : (<ListItemButton>
                                    <ListItemText primary="Herhangi bir çocuk bulunamadı" />
                                </ListItemButton>)
                            }
                        </List>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Adres
                        </Typography>
                        <Divider></Divider>
                        <List>
                            {
                                this.state.locations?.length>0 ? (
                                    <>
                                        {this.state.locations.map((location,i)=>(
                                            <ListItemButton LinkComponent={'a'} onDoubleClick={()=>{googleMaps(location?.Ikametgah)}} onClick={()=>{this.copyValue(location?.Ikametgah,"Adres")}} key={i}>
                                                <ListItemText primary={<a>{`${location?.Ikametgah}`}</a>} />
                                            </ListItemButton>
                                        ))}
                                    </>
                                ) : (<ListItemButton>
                                    <ListItemText primary="Herhangi bir adres bulunamadı" />
                                </ListItemButton>)
                            }
                        </List>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Vergi Numarası
                        </Typography>
                        <Divider></Divider>
                        <List>
                            {
                                this.state.locations?.length>0 ? (
                                    <>
                                        {this.state.locations.map((location,i)=>(
                                            <ListItemButton onClick={()=>{this.copyValue(location?.VergiNumarasi,"Vergi Numarası")}} LinkComponent={'a'} key={i}>
                                                <ListItemText primary={<a>{`${location?.VergiNumarasi}`}</a>} />
                                            </ListItemButton>
                                        ))}
                                    </>
                                ) : (<ListItemButton>
                                    <ListItemText primary="Herhangi bir vergi numarası bulunamadı" />
                                </ListItemButton>)
                            }
                        </List>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Doğum Yeri
                        </Typography>
                        <Divider></Divider>
                        <List>
                            {
                                this.state.locations?.length>0 ? (
                                    <>
                                        {this.state.locations.map((location,i)=>(
                                            <ListItemButton LinkComponent={'a'} onClick={()=>{this.copyValue(location?.VergiNumarasi,"Adres")}} key={i}>
                                                <ListItemText primary={<a>{`${location?.DogumYeri}`}</a>} />
                                            </ListItemButton>
                                        ))}
                                    </>
                                ) : (<ListItemButton>
                                    <ListItemText primary="Herhangi bir doğum yeri bulunamadı" />
                                </ListItemButton>)
                            }
                        </List>
                        
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Telefon Numaraları
                        </Typography>
                        <Divider></Divider>
                        <List>
                            {
                                this.state.numbers?.length>0 ? (
                                    <>
                                        {this.state.numbers.map((number,i)=>(
                                            <ListItemButton LinkComponent={'a'} onClick={()=>{this.copyValue(number?.GSM,number?.GSM)}} key={i}>
                                                <ListItemText primary={<a href={`tel:+90${number?.GSM}`}>{`+90${number?.GSM}`}</a>} />
                                            </ListItemButton>
                                        ))}
                                    </>
                                ) : (<ListItemButton>
                                    <ListItemText primary="Herhangi bir telefon numarası bulunamadı" />
                                </ListItemButton>)
                            }
                        </List>
                        
                        
                    </Grid>
                    <Grid item md={12} xs={12} my={2}>
                        <ExclusiveDatagrid
                            title="Çalıştığı işyerleri"
                            columns={jobsColumns}
                            data={this.state.jobs}
                            startWithActions={true}
                            actions={[
                                (params)=><GridActionsCellItem icon={<RemoveRedEye/>} onClick={()=>{this.JobDetails(params.row)}} label="Details" />
                            ]}
                        ></ExclusiveDatagrid>
                        <ExclusiveDatagrid
                            title="Yetkili Olduğu İşyerleri"
                            columns={jobsColumnsA}
                            data={this.state.jobsauthorities}
                            startWithActions={true}
                            actions={[
                                (params)=><GridActionsCellItem icon={<RemoveRedEye/>} onClick={()=>{this.JobDetails(params.row)}} label="Details" />
                            ]}
                        ></ExclusiveDatagrid>
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
    jobModal(){
        let handleClose = ()=>{
            this.setState(state=>{
                return {
                    ...state,
                    showJobInfoModal:false
                }
            })
        }
        let jobsColumnsA = [
            {
                field: 'yetkiliTckn',
                headerName: 'TC',
                width: 150,
                primary:true
            },
            
            {
                field: 'yetkiliAdSoyad',
                headerName: 'Ad Soyad',
                width: 200
            },    
            {
                field: 'yetkiTuru',
                headerName: 'Yetki Türü',
                width: 200
            },    
            {
                field: 'yetkililikDurumu',
                headerName: 'Durum',
                width: 140
            },
            
            
        ]
        let jobsColumns = [
            {
                field: 'calisanId',
                headerName: 'Çalışan ID',
                width: 110,
                primary:true
            }, 
            {
                field: 'belgeTur',
                headerName: 'Belge Türü',
                width: 130
            }, 
            {
                field: 'calisanKimlikNo',
                headerName: 'TC',
                width: 150
            },
            {
                field: 'calisanAdSoyad',
                headerName: 'Ad Soyad',
                width: 200
            },    
            {
                field: 'isciId',
                headerName: 'Sicil No',
                width: 90
            },    
            {
                field: 'iseGirisTarihi',
                headerName: 'Giriş Tarihi',
                width: 260
            },
            {
                field: 'calismaDurumu',
                headerName: 'Durum',
                width: 140
            },
            
            
        ]
        return (
            <Dialog
                fullScreen
                open={this.state.showJobInfoModal}
                onClose={handleClose}
                // TransitionComponent={React.forwardRef(function Transition(props, ref) {
                //     return <Slide direction="up" ref={ref} {...props} />;
                //   })}
            >
                <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {this.state.jobTarget?.isyeriUnvani} Detayları
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                    Kapat
                    </Button>
                </Toolbar>
                </AppBar>
                <Grid container spacing={2} >
                    <Grid item  md={12} xs={12}>
                        <List>
                            <ListItemButton>
                                <ListItemText primary="Ünvan" onClick={()=>{this.copyValue(this.state.jobTarget?.isyeriUnvani,"Ünvan")}}  secondary={this.state.jobTarget?.isyeriUnvani} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="İşyeri Kodu" onClick={()=>{this.copyValue(this.state.jobTarget?.isyeriId,"İşyeri Kodu")}}  secondary={this.state.jobTarget?.isyeriId} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="SGK Sicil No" onClick={()=>{this.copyValue(this.state.jobTarget?.isyeriSgkSicilNo,"SGK Sicil No")}}  secondary={this.state.jobTarget?.isyeriSgkSicilNo} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="NACE Kodu" onClick={()=>{this.copyValue(this.state.jobTarget?.isyeriNaceKodu,"NACE Kodu")}}  secondary={this.state.jobTarget?.isyeriNaceKodu} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="Tehlike Sınıfı" onClick={()=>{this.copyValue(this.state.jobTarget?.isyeriTehlikeSinifi,"Tehlike Sınıfı")}}  secondary={this.state.jobTarget?.isyeriTehlikeSinifi} />
                            </ListItemButton>
                            <ListItemButton>
                                <ListItemText primary="İş Tanımı" onClick={()=>{this.copyValue(this.state.jobTarget?.isyeriSektoru,"İş Tanımı")}}  secondary={this.state.jobTarget?.isyeriSektoru} />
                            </ListItemButton>
                            

                        </List>
                    </Grid>
                    <Grid item md={12} xs={12} my={2}>
                        <ExclusiveDatagrid
                            title={`Yetkililer`}
                            columns={jobsColumnsA}
                            data={this.state.jobTargetJobAuthorities ?? []}
                            startWithActions={true}
                            actions={[
                                (params)=><GridActionsCellItem icon={<RemoveRedEye/>} onClick={()=>{this.TargetAimByTc(params.row.yetkiliTckn)}} label="Details" />
                            ]}
                        ></ExclusiveDatagrid>
                        
                    </Grid>
                    <Grid item md={12} xs={12} my={2}>
                        <ExclusiveDatagrid
                            title={`Çalışanları`}
                            columns={jobsColumns}
                            data={this.state.jobTargetJobWorkers ?? []}
                            startWithActions={true}
                            
                            actions={[
                                (params)=><GridActionsCellItem icon={<RemoveRedEye/>} onClick={()=>{this.TargetAimByTc(params.row.calisanKimlikNo)}} label="Details" />
                            ]}
                        ></ExclusiveDatagrid>
                        
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
    loadingScreen(){
        return (<Backdrop
            sx={(theme) => ({  zIndex: theme.zIndex.drawer + 1 })}
            open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>)
    }
    showMessage(message){
        this.setState(state=>{
            return {
                ...state,
                snackBarOpen:true,
                snackBarMessage:message
            }
        })
    }
    snackBar(){
        return (<Snackbar
            anchorOrigin={{vertical:"bottom",horizontal:"right"}}
            open={this.state.snackBarOpen}
            onClose={()=>{
                this.setState((state)=>{
                    return {
                        ...state,
                        snackBarOpen:false
                    }
                })
            }}
            autoHideDuration={5000}
            message={this.state.snackBarMessage}
        />)
    }
    async TargetAimByTc(target){
        let row = (await LysepAPI.post("/api/piratecall/query",{TC:target})).data;
        console.log("row is",row)
        if(row.results?.length>0){
            this.TargetAim(row.results[0]);
        }else{
            toast.error(`${target} not found in LYSEP database`);
        }
        
    }
    TargetAim(newTarget){
        let TargetAimReal = ()=>new Promise(async (resolve,reject)=>{
            let row = newTarget
            if(row === undefined){
                this.showMessage("Hata");
                reject("Hata")
                return;
            }
            let PostDialog = (parents,childs,numbers,locations_,jobs_,jobsauthorities_)=>{
                this.setState(state=>{
                    return {
                        ...state,
                        showInfoModal:true,
                        target:row,
                        parents:parents,
                        childs:childs,
                        numbers:numbers,
                        locations:locations_,
                        jobs:jobs_,
                        jobsauthorities:jobsauthorities_,
                        loading:false
                    }
                })
            }
            try {
                let relationships = (await LysepAPI.post("/api/piratecall/relationship",{TC:row.TC})).data;
                let numbers = (await LysepAPI.post("/api/piratecall/query/gsm/tc",{TC:row.TC})).data;
                let locations = (await LysepAPI.post("/api/piratecall/query/adres/tc",{TC:row.TC})).data;
                let jobs = (await LysepAPI.post("/api/piratecall/query/sgk/tc",{TC:row.TC})).data;
                let jobsauthorities = (await LysepAPI.post("/api/piratecall/query/sgk/tc/yetkili",{TC:row.TC})).data;
                if(!relationships.state){
                }
                if(!numbers.state){
                }
                let parents_ = relationships.parents ?? []
                let childs_ = relationships.childs ?? []
                let numbers_ = numbers.results ?? []
                let locations_ = locations.results ?? []
                let jobs_ = jobs.results ?? []
                let jobsauthorities_ = jobsauthorities.results ?? []
                jobsauthorities_ = jobsauthorities_.map((t,i)=>{
                    return {
                        id:i,
                        ...t
                    }
                })
                PostDialog(parents_,childs_,numbers_,locations_,jobs_,jobsauthorities_)
                resolve()
            } catch (error) {
                this.showMessage("Hata veri çekilemedi",error)
                reject(error)
                console.error(error)
            }
        })
            

        this.setState((state)=>{
            return {
                ...state,
                loading:true,
                showInfoModal:false
            }
        },()=>{
            let promise = TargetAimReal()
            console.log("promise is",promise)
            toast.promise(
                promise,
                {
                    pending:`Targetting to ${newTarget.ADI}...`,
                    success:()=>{
                        return `Targeting complete.`;
                    },
                    error:(err)=>{
                        return `Error occurred: ${err.message} :@`;
                    }
                },{
                    autoClose:1000
                }
            )
        })
    }
    JobDetails(newTarget){
        let TargetJobReal = ()=>new Promise(async (resolve,reject)=>{
            let row = newTarget
            if(row === undefined){
                this.showMessage("Hata");
                reject("Hata")
                return;
            }
            let PostDialog = (jobWorkers,authorities)=>{
                this.setState(state=>{
                    return {
                        ...state,
                        showJobInfoModal:true,
                        jobTarget:newTarget,
                        jobTargetJobWorkers:jobWorkers,
                        jobTargetJobAuthorities:authorities,
                        loading:false
                    }
                })
            }
            try {
                let workers = (await LysepAPI.post("/api/piratecall/query/sgk/isyeri",{isyeriId:row.isyeriId})).data;
                let authorities = (await LysepAPI.post("/api/piratecall/query/sgk/isyeri/yetkili",{isyeriId:row.isyeriId})).data;
                let workers_ = workers.results ?? []
                let authorities_ = authorities.results ?? []
                authorities_ = authorities_.map((t,i)=>{

                    return {
                        ...t,
                        id:i
                    }
                });
                PostDialog(workers_,authorities_)
                resolve()
            } catch (error) {
                this.showMessage("Hata veri çekilemedi",error)
                reject(error)
                console.error(error)
            }
        })
            

        this.setState((state)=>{
            return {
                ...state,
                loading:true
            }
        },()=>{
            let promise = TargetJobReal()
            console.log("promise is",promise)
            toast.promise(
                promise,
                {
                    pending:`Targetting to ${newTarget.isyeriUnvani}...`,
                    success:()=>{
                        return `Targeting complete.`;
                    },
                    error:(err)=>{
                        return `Error occurred: ${err.message} :@`;
                    }
                },{
                    autoClose:1000
                }
            )
        })
    }
    render() {
        const columns = [
            {
                field: 'TC',
                headerName: 'TC',
                width: 150,
                primary:true
            },
            {
                field: 'ADI',
                headerName: 'ADI',
                width: 150,
            },
            {
                field: 'SOYADI',
                headerName: 'SOYADI',
                width: 150,
            },
            {
                field: 'DOGUMTARIHI',
                headerName: 'DOĞUM TARİHİ',
                width: 150,
            },
            {
                field: 'ANNEADI',
                headerName: 'ANNE ADI',
                width: 150,
            },
            {
                field: 'BABAADI',
                headerName: 'BABA ADI',
                width: 150,
            },
            {
                field: 'NUFUSIL',
                headerName: 'NÜFUS İL',
                width: 150,
            },
            {
                field: 'NUFUSILCE',
                headerName: 'NÜFUS İLÇE',
                width: 150,
            },
            {
                field: 'UYRUK',
                headerName: 'UYRUK',
                width: 150,
            }
        ];
        console.log("states",this.state.queryPool)
        return (
            <>
                    <Box sx={{ padding:'1em' }} >
                            <Paper elevation={2}>
                                <Box width={"100%"}>
                                    <Grid container spacing={3} paddingX={2} paddingY={4}>
                                        <Grid item xs={12}>
                                            <Typography variant='h3' textAlign={'center'}>PIRATE CALL</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckedSearch
                                                title="TC"
                                                isDisabled = {this.state.searchMode === SearchModeBase.TEL}
                                                textValue={this.queryPoolTextValue("TC")}
                                                textOnChange={e=>this.queryPoolTextState("TC",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("TC",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("TC") }/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                            
                                                title="ADI"
                                                isDisabled = {this.state.searchMode === SearchModeBase.TEL}
                                                textValue={this.queryPoolTextValue("ADI")}
                                                textOnChange={e=>this.queryPoolTextState("ADI",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("ADI",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("ADI")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                                title="SOYADI"
                                                isDisabled = {this.state.searchMode === SearchModeBase.TEL}
                                                textValue={this.queryPoolTextValue("SOYADI")}
                                                textOnChange={e=>this.queryPoolTextState("SOYADI",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("SOYADI",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("SOYADI")}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckedSearch
                                                title="DOĞUM TARİHİ"
                                                isDisabled = {this.state.searchMode === SearchModeBase.TEL}
                                                textValue={this.queryPoolTextValue("DOGUMTARIHI")}
                                                textOnChange={e=>this.queryPoolTextState("DOGUMTARIHI",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("DOGUMTARIHI",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("DOGUMTARIHI")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                            
                                                title="ANNE ADI"
                                                isDisabled = {this.state.searchMode === SearchModeBase.TEL}
                                                textValue={this.queryPoolTextValue("ANNEADI")}
                                                textOnChange={e=>this.queryPoolTextState("ANNEADI",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("ANNEADI",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("ANNEADI")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                                title="ANNE TC"
                                                isDisabled = {this.state.searchMode === SearchModeBase.TEL}
                                                textValue={this.queryPoolTextValue("ANNETC")}
                                                textOnChange={e=>this.queryPoolTextState("ANNETC",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("ANNETC",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("ANNETC")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                            
                                                title="BABA ADI"
                                                isDisabled = {this.state.searchMode === SearchModeBase.TEL}
                                                textValue={this.queryPoolTextValue("BABAADI")}
                                                textOnChange={e=>this.queryPoolTextState("BABAADI",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("BABAADI",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("BABAADI")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                                title="BABA TC"
                                                isDisabled = {this.state.searchMode === SearchModeBase.TEL}
                                                textValue={this.queryPoolTextValue("BABATC")}
                                                textOnChange={e=>this.queryPoolTextState("BABATC",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("BABATC",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("BABATC")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                            
                                                title="NÜFUS İL"
                                                isDisabled = {this.state.searchMode === SearchModeBase.TEL}
                                                textValue={this.queryPoolTextValue("NUFUSIL")}
                                                textOnChange={e=>this.queryPoolTextState("NUFUSIL",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("NUFUSIL",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("NUFUSIL")}/>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CheckedSearch
                                                title="NÜFUS İLÇE"
                                                isDisabled = {this.state.searchMode === SearchModeBase.TEL}
                                                textValue={this.queryPoolTextValue("NUFUSILCE")}
                                                textOnChange={e=>this.queryPoolTextState("NUFUSILCE",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("NUFUSILCE",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("NUFUSILCE")}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckedSearch
                                                title="UYRUK"
                                                isDisabled = {this.state.searchMode === SearchModeBase.TEL}
                                                textValue={this.queryPoolTextValue("UYRUK")}
                                                textOnChange={e=>this.queryPoolTextState("UYRUK",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("UYRUK",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("UYRUK")}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckedSearch
                                                title="GSM"
                                                textValue={this.queryPoolTextValue("GSM")}
                                                textOnChange={e=>this.queryPoolTextState("GSM",e.target.value)}
                                                checkboxOnChange={e=>this.queryPoolCheckboxState("GSM",e.target.checked)}
                                                checkboxValue={this.queryPoolCheckValue("GSM")}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                        <Button variant="contained" startIcon={<Search />} onClick={this.fetch}>
                                            ARAMA YAP
                                        </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                        <ExclusiveDatagrid
                            title="Sonuçlar"
                            columns={columns}
                            data={this.state.result}
                            startWithActions={true}
                            actions={[
                                (params)=><GridActionsCellItem icon={<RemoveRedEye/>} onClick={()=>{this.TargetAim(params.row)}} label="Details" />
                            ]}
                        >
                
                        </ExclusiveDatagrid>
                    </Box>
                    {this.snackBar()}
                    {this.dataModal()}
                    {this.jobModal()}
                    {this.loadingScreen()}
        </>
        )
        
    }
}