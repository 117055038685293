import React, { Component } from 'react'
import { Link } from 'react-router-dom';
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './Sidebar.scss';
import Logo from './LogoComponent';
import { DashboardConsumer } from '../PanelRoutes/Dashboard';
import { LSRoutes } from '../../LYSEPHelper';

export default class Sidebar extends Component {
    constructor(props){
        super(props)
    }
    componentDidMount(){
        this.linkerButtonsHandle()
    }
    linkerButtonsHandle(){
        var plist = document.querySelector("div.sidebar div.wrapper ul.page-lists")
        var buttons = plist.querySelectorAll("li");
        // buttons.forEach(button=>{
        //     button.onclick = (event)=>{
        //         console.log(event)
        //         /*
        //             Deactive all buttons
        //         */
        //         buttons.forEach(target=>{
        //             if(target.getAttribute("active") == "1"){
        //                 target.setAttribute("active",0)
        //             }
        //         })
        //         /**
        //          * Active Target
        //          */
        //         button.setAttribute("active",1)
        //     }
        // })
    }
    render() {
        return (
            <DashboardConsumer>
                {DashboardState=>(
                     <div className="sidebar">
                         {console.log("AAA",DashboardState.currentSubUrl==LSRoutes.Dashboard(""),DashboardState,LSRoutes.Dashboard("users"))}
                     {/* <div className="logo">
                         <Logo color="#fd7000" className="lysep-logo"/>
                         <div className="brand">
                             <a href="https://lysep.com">LYSEP</a>
                         </div>
                     </div>
                     <div className="horizontal-line"></div> */}
                     <div className="wrapper">
                         <ul className="page-lists">
                             {this.props.children}
                             
                         </ul>
                     </div>
                     <div className="footer">
                         <div>
                             <a>© 2020, Coded by <a href="https://lysep.com">LYSEP</a></a>
                         </div>
                     </div>
                 </div>
                )

                }
            </DashboardConsumer>
            )
    }
}
